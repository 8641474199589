import Component from '@glimmer/component';

import { action } from '@ember/object';

interface UiAttachmentTooltipSignature {
  Args: {
    guid: string;
    isEnabled?: boolean;
    displayTooltip?: boolean;
    isPopover?: boolean;
    isLarge?: boolean;
    hideTooltip: Function;

    bgColor?: string;
    borderColor?: string;
    textColor?: string;
    anchor?: string;

    tetherClasses?: string;
    useShadow?: boolean;
    testSelector?: string;
    zIndex?: string;
    classes?: string;
  };
  Blocks: {
    default: [];
  };
}

export default class UiAttachmentTooltipComponent extends Component<UiAttachmentTooltipSignature> {
  get isEnabled() {
    return this.args.isEnabled ?? true;
  }

  get displayTooltip() {
    return this.args.displayTooltip ?? false;
  }

  get isPopover(): boolean {
    return this.args.isPopover;
  }

  get isTooltip(): boolean {
    return !this.isPopover;
  }

  get isOpen(): boolean {
    return this.isEnabled && this.displayTooltip;
  }

  get borderColor() {
    return this.args.borderColor ?? 'black';
  }

  get bgColor() {
    if (this.args.bgColor) return this.args.bgColor;
    if (this.isPopover) return 'white';
    return 'black';
  }

  get textColor() {
    if (this.args.textColor) return this.args.textColor;
    if (this.isPopover) return 'black';
    return 'white';
  }

  get isLarge() {
    if (this.args.isLarge) return this.args.isLarge;
    return this.isPopover;
  }

  get anchorTokens() {
    return this.args.anchor.split?.(' ');
  }

  get firstToken(): string {
    return this.anchorTokens[0];
  }

  get secondToken() {
    const secondToken = this.anchorTokens.objectAt(1);
    return secondToken || 'center';
  }

  get axis() {
    if (['top', 'bottom'].includes(this.firstToken)) {
      return 'x';
    }
    if (['left', 'right'].includes(this.firstToken)) {
      return 'y';
    }
    return undefined;
  }

  get axisPosition() {
    if (this.axis === 'x') {
      switch (this.secondToken) {
        case 'left':
          return 'start';
        case 'right':
          return 'end';
        default:
          return 'center';
      }
    }

    if (this.axis === 'y') {
      switch (this.secondToken) {
        case 'top':
          return 'start';
        case 'bottom':
          return 'end';
        default:
          return 'center';
      }
    }
    return undefined;
  }

  get positionClasses() {
    if (this.secondToken !== 'center') {
      return `-m${this.secondToken.substring(0, 1)}-3`;
    }
    return '';
  }

  get colorClasses() {
    return `bg-${this.bgColor} border-${this.borderColor} text-${this.textColor}`;
  }

  get target() {
    return `[data-tether-target-id="${this.args.guid}"]`;
  }

  get targetAttachment() {
    const { axis, firstToken, secondToken } = this;

    if (axis === 'x') {
      return `${firstToken} ${secondToken}`;
    }
    if (axis === 'y') {
      return `${secondToken} ${firstToken}`;
    }
    return undefined;
  }

  get attachment() {
    const { axis, firstToken, secondToken } = this;

    const inverseFor = (point: string) => {
      switch (point) {
        case 'top':
          return 'bottom';
        case 'bottom':
          return 'top';
        case 'left':
          return 'right';
        case 'right':
          return 'left';
        default:
          return '';
      }
    };

    if (axis === 'x') {
      return `${inverseFor(firstToken)} ${secondToken}`;
    }
    if (axis === 'y') {
      return `${secondToken} ${inverseFor(firstToken)}`;
    }

    return undefined;
  }

  get isArrowBefore() {
    return ['bottom', 'right'].includes(this.firstToken);
  }

  get isArrowAfter(): boolean {
    return !this.isArrowBefore;
  }

  @action
  onPosition(element: HTMLElement) {
    if (!element) return;

    const arrow = element.querySelector(
      '.tooltip-arrow-container'
    ) as HTMLElement;
    const rect = element.getBoundingClientRect();
    const padding = 30; // a bit of visual padding

    // If the rect is bigger than the window shrink it
    if (rect.width > window.innerWidth) {
      element.style.maxWidth = `${window.innerWidth - padding}px`;
    } else {
      element.style.maxWidth = `${window.innerWidth / 2}px`;
    }

    // if the rect overflows the right
    if (rect.x + rect.width > window.innerWidth) {
      const result = rect.width + rect.x + padding - window.innerWidth;
      element.style.left = `-${result}px`;
      if (arrow) {
        // Get the percentage of change and move the arrow that far over
        // with a max of 45 to prevent the arrow moving too far
        arrow.style.marginLeft = `${Math.min(45, (result / rect.width) * 100)}%`;
      }
    }

    // If rect.x is > 0 the tooltip is out of bounds on the left
    if (rect.x < 0) {
      element.style.left = `${Math.abs(rect.x)}px`;
      if (arrow) arrow.style.marginLeft = `-${Math.abs(rect.x)}px`;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UiAttachment::Tooltip': typeof UiAttachmentTooltipComponent;
    'ui-attachment/tooltip': typeof UiAttachmentTooltipComponent;
  }
}
